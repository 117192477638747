const mockUserCapacity = [{
  ticketCount: 0,
  maxCapacity: 0,
  loading: false
}, {
  ticketCount: 1,
  maxCapacity: 4,
  loading: false
}, {
  ticketCount: 3,
  maxCapacity: 5,
  loading: false
}, {
  ticketCount: 4,
  maxCapacity: 4,
  loading: false
}, {
  ticketCount: 6,
  maxCapacity: 4,
  loading: false
}];
export const useUserLevelCapacityLimits = agentId => {
  return mockUserCapacity[(agentId || 0) % mockUserCapacity.length];
};