import { CallWidgetStates } from 'calling-internal-common/widget-status/constants/CallWidgetStates';
import { UUID } from 'shared-worker-versioning/lib/utils/UUID';
import { CriticalFailureReasons } from '../../constants/CriticalFailureReasons';
import { WEB_WORKER_VERSION } from '../../constants/WebWorkerVersion';
const initalUUID = UUID();
export const getInitialAppData = () => ({
  version: WEB_WORKER_VERSION,
  deprecated: false,
  widgetDetails: {
    uuid: initalUUID,
    callStatus: CallWidgetStates.READY,
    activeCallDetails: undefined,
    standaloneConnectionId: undefined,
    deviceErrorCode: undefined,
    startCallError: undefined,
    criticalFailureReason: CriticalFailureReasons.NONE,
    callsV1SettingsUsage: undefined,
    transferInformation: undefined,
    twilioDeviceStatus: undefined
  }
});