import { getViewMemberId } from 'find-and-filter-data/view-members-schema/public';
import Raven from 'raven-js';
export function mergeAdded(addedMembers, viewMembers) {
  let addedCount = 0;
  const indexedRecords = addedMembers.reduce((indexedMembers, currViewMember) => {
    try {
      //If the member being added already exists, don't add it or increment count
      if (indexedMembers.get(getViewMemberId(currViewMember))) {
        return indexedMembers;
      }
      addedCount = addedCount + 1;
      return indexedMembers.set(getViewMemberId(currViewMember), currViewMember);
    } catch (e) {
      Raven.captureException(e, {
        extra: {
          currViewMemberType: typeof currViewMember,
          indexedMembersType: typeof indexedMembers,
          viewMemberId: `${getViewMemberId(currViewMember)}`
        }
      });
      return indexedMembers;
    }
  }, viewMembers);
  return {
    indexedRecords,
    addedCount
  };
}
export function mergeUpserted(upsertedMembers, viewMembers) {
  let upsertedCount = 0;
  const indexedRecords = upsertedMembers.reduce((indexedMembers, currViewMember) => {
    try {
      //If the member being upserted doesn't already exist, increment count.
      if (!indexedMembers.get(getViewMemberId(currViewMember))) {
        upsertedCount = upsertedCount + 1;
      }
      return indexedMembers.set(getViewMemberId(currViewMember), currViewMember);
    } catch (e) {
      Raven.captureException(e, {
        extra: {
          currViewMemberType: typeof currViewMember,
          indexedMembersType: typeof indexedMembers,
          viewMemberId: `${getViewMemberId(currViewMember)}`
        }
      });
      return indexedMembers;
    }
  }, viewMembers);
  return {
    indexedRecords,
    upsertedCount
  };
}
export function mergeUpdated(updatedViewMembers, viewMembers) {
  const indexedRecords = updatedViewMembers.reduce((indexedMembers, currViewMember) => {
    try {
      //If the member being updated doesn't already exist, don't add/update it.
      if (!indexedMembers.get(getViewMemberId(currViewMember))) {
        return indexedMembers;
      }
      return indexedMembers.set(getViewMemberId(currViewMember), currViewMember);
    } catch (e) {
      Raven.captureException(e, {
        extra: {
          currViewMemberType: typeof currViewMember,
          indexedMembersType: typeof indexedMembers,
          viewMemberId: `${getViewMemberId(currViewMember)}`
        }
      });
      return indexedMembers;
    }
  }, viewMembers);
  return indexedRecords;
}
export function mergeRemovedTickets(removedIds, viewMembers) {
  return removedIds.reduce((members, removedId) => {
    try {
      return members.delete(`${removedId}`);
    } catch (e) {
      Raven.captureException(e, {
        extra: {
          currViewMemberType: typeof members.get(`${removedId}`),
          viewMemberId: `${removedId}`
        }
      });
      return members;
    }
  }, viewMembers);
}

/**
 * @description This function will take a realtime ViewUpdate message and
 * apply all the changes to the given map of ViewMembers, returning a new map
 * with all those applied changes.
 *
 * @param viewMembers
 * @param viewMembersUpdated
 */
export function applyHelpDeskViewUpdateMessage(viewMembers, viewMembersUpdated) {
  const {
    indexedRecords: addedMembers
  } = mergeAdded(viewMembersUpdated.added, viewMembers);
  const updatedMembers = mergeUpdated(viewMembersUpdated.updated, addedMembers);
  return updatedMembers;
}
export function applyHelpDeskBulkUpdateMessage(viewMembers, viewMembersUpdated) {
  const {
    indexedRecords: addedMembers,
    addedCount
  } = mergeAdded(viewMembersUpdated.added, viewMembers);
  const {
    indexedRecords: upsertedMembers,
    upsertedCount
  } = mergeUpserted(viewMembersUpdated.updated, addedMembers);
  let count = addedCount + upsertedCount;
  let removedCount = 0;
  if (viewMembersUpdated.removed) {
    const updatedMembers = mergeRemovedTickets(viewMembersUpdated.removed, upsertedMembers);
    removedCount = viewMembersUpdated.removed.length;
    count = addedCount + upsertedCount - removedCount;
    return {
      members: updatedMembers,
      count
    };
  }
  return {
    members: upsertedMembers,
    count
  };
}