import { evaluateFilterBranch } from '../evaluation/evaluateFilterBranch';
import { buildThreadListMemberUpdatePatch } from './buildThreadListMemberUpdatePatch';
import { viewMemberPatched } from './viewMembersUpdatesSlice';

/**
 * @description This function will evaluate a threadListMember and ticket against
 * the given viewFilterProperties and will create a patch based on the result of
 * the evaluation.
 */
export const evaluateMemberAndCreatePatch = ({
  differences,
  dispatch,
  threadId,
  threadListMemberOrDetails,
  ticket,
  viewFilterProperties
}) => {
  if (!threadListMemberOrDetails || !threadId || !viewFilterProperties) return;
  const evaluationResult = evaluateFilterBranch(viewFilterProperties, threadListMemberOrDetails, ticket);
  if (evaluationResult === 'PASSED' || evaluationResult === 'FAILED') {
    const threadListMemberUpdatePatch = buildThreadListMemberUpdatePatch({
      propertyPasses: evaluationResult === 'PASSED',
      threadId,
      threadListMemberOrDetails,
      updatedTLMProperties: differences
    });
    dispatch(viewMemberPatched(threadListMemberUpdatePatch));
  }
};