import UIButton from 'UIComponents/button/UIButton';
import { MAGENTA, BLACK, WHITE, GRADIENT } from '../../../../styles/colors';
import styled, { css } from 'styled-components';
import { BreezeTertiaryAIBaseButton } from './StyledAIBaseButton';
const DropdownButtonIconStyle = css([".uiDropdown__buttonContents{display:inline-block;.uiDropdown__buttonLabel{margin-right:0.5rem;}}"]);
const IconStyle = css([".ai-icon{position:relative;bottom:1px;}"]);
export const BreezeLinkOnDarkCopilotBaseButton = styled(UIButton).withConfig({
  displayName: "StyledCopilotBaseButton__BreezeLinkOnDarkCopilotBaseButton",
  componentId: "jdp32q-0"
})(["border-radius:2rem !important;border:none !important;color:", " !important;text-decoration:none !important;&:not(.disabled) .ai-icon,&:not(.disabled) span{transition:all 150ms ease-out !important;color:", " !important;}&:not(.disabled):hover .ai-icon,&:not(.disabled):hover span{color:", " !important;}&:active{color:", " !important;}", " ", ""], WHITE, WHITE, MAGENTA[300], GRADIENT['DARK'], DropdownButtonIconStyle, IconStyle);
export const BreezeTransparentCopilotBaseButton = styled(UIButton).withConfig({
  displayName: "StyledCopilotBaseButton__BreezeTransparentCopilotBaseButton",
  componentId: "jdp32q-1"
})(["border-radius:2rem !important;border:none !important;color:", " !important;text-decoration:none !important;&:not(.disabled) .ai-icon,&:not(.disabled) span{transition:all 150ms ease-out !important;color:", " !important;}&:not(.disabled):hover .ai-icon,&:not(.disabled):hover span{color:", " !important;}&:active{color:", " !important;}", " ", ""], BLACK['NEUTRAL'], BLACK['NEUTRAL'], MAGENTA[500], GRADIENT['DARK'], DropdownButtonIconStyle, IconStyle);

/** These styles are specific to Copilot Action Button
 * These Copilot inovations are different than standard AIButtons
 */
export const getStyledCopilotButton = use => {
  switch (use) {
    case 'transparent':
      return BreezeTransparentCopilotBaseButton;
    case 'link-on-dark':
      return BreezeLinkOnDarkCopilotBaseButton;
    case 'tertiary':
      return BreezeTertiaryAIBaseButton;
    default:
      return BreezeTransparentCopilotBaseButton;
  }
};