import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { viewMemberUpdated } from 'find-and-filter-data/realtime-updates/public';
import { generateViewId } from 'find-and-filter-data/views-schema/public';
import { Map as ImmutableMap } from 'immutable';
import set from 'transmute/set';
import { AsyncStatus } from '../../common/public';
import { omnibusFulfilled } from '../../omnibus/public';
import { applyViewUpdateMessage } from '../../realtime-updates/public';
import { mergeViewMembersWithUpdates } from '../../realtime-view-member/protected';
import { fetchSearchAndFilterViewMembers } from '../../search-and-filter-data/protected';
import { threadsUpdated } from '../../view-member-optimistic-updates/public';
import { getMembers } from '../../view-members-schema/protected';
import { mergeIncomingViewMembersPage } from './common/fetchViewMembersHelpers';
import { threadSeen } from './common/sharedViewMemberActions';
import { SPAM_THREAD_LIST_ID } from './thread-list-member/constants';
import { buildViewMembersPage } from './view-member-page/buildViewMembersPage';
import { fetchSpamViewMembers, fetchViewMembers } from './viewMemberActions';
const initialState = {
  data: ImmutableMap(),
  //This defaults to false so that we will fetch viewMembers if omnibus call fails.
  loading: false,
  failed: false,
  status: AsyncStatus.UNINITIALIZED
};
const updateThreadListMembers = ({
  data,
  viewId,
  currentAgentId,
  message
}) => {
  return data.update(viewId, page => {
    if (!page) return page;
    return page.update('indexedMembers', members => {
      const updatedMembers = applyViewUpdateMessage(members, message, currentAgentId);
      return updatedMembers;
    });
  });
};
export const viewMembersSlice = createSlice({
  name: 'viewMembers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(omnibusFulfilled, (state, {
      payload
    }) => {
      state.loading = false;
      if (payload.omnibus.threadListMembers) {
        state.data = ImmutableMap({
          [payload.currentViewId]: buildViewMembersPage(payload.omnibus.threadListMembers)
        });
        state.status = AsyncStatus.SUCCEEDED;
      }
    }).addCase(threadSeen, (state, action) => {
      const {
        payload
      } = action;
      const {
        threadId,
        seen,
        customViewIds,
        threadLists
      } = payload;
      let newState = state.data;

      //Update the ViewMember record in cached custom views.
      for (const viewId of customViewIds) {
        newState = newState.updateIn([`${viewId}`, 'indexedMembers', threadId], viewMember => {
          return viewMember && viewMember.set('seen', seen);
        });
      }

      //Update the ViewMember record in cached thread lists.
      for (const {
        threadListId,
        threadListType
      } of threadLists) {
        const threadListIdString = generateViewId({
          threadListId,
          threadListType
        });
        newState = newState.updateIn([threadListIdString, 'indexedMembers', threadId], viewMember => {
          return viewMember && viewMember.set('seen', seen);
        });
      }
      state.data = newState;
    }).addCase(threadsUpdated, (state, {
      payload: {
        currentAgentId,
        message
      }
    }) => {
      const viewId = generateViewId({
        customViewId: message.customViewId,
        threadListId: message.threadListId,
        threadListType: message.threadListType
      });
      if (state.data.get(viewId)) {
        state.data = state.data.update(viewId, page => {
          const updatedMembers = mergeViewMembersWithUpdates(getMembers(page), message, currentAgentId);
          return set('indexedMembers', updatedMembers, page);
        });
      }
    }).addCase(viewMemberUpdated, (state, action) => {
      const {
        currentAgentId,
        message
      } = action.payload;
      const viewId = generateViewId(message.viewKey);
      state.data = updateThreadListMembers({
        data: state.data,
        viewId,
        currentAgentId,
        message
      });
    }).addMatcher(isAnyOf(fetchSpamViewMembers.pending, fetchViewMembers.pending, fetchSearchAndFilterViewMembers.pending), (state, {
      meta
    }) => {
      state.failed = false;
      const disabledLoad = meta.arg && meta.arg.options && meta.arg.options.disabledLoad;
      if (!disabledLoad) {
        if (!meta.arg || meta.arg.offsetId === undefined || meta.arg.offsetId < 0) {
          state.status = AsyncStatus.INITIAL_FETCH_LOADING;
          state.data = ImmutableMap();
        } else {
          state.status = AsyncStatus.PAGINATED_FETCH_LOADING;
        }
        state.loading = true;
      }
    }).addMatcher(isAnyOf(fetchSpamViewMembers.fulfilled, fetchViewMembers.fulfilled, fetchSearchAndFilterViewMembers.fulfilled), (state, {
      meta,
      payload,
      type
    }) => {
      if (meta.arg && meta.arg.offsetId && meta.arg.offsetId > 0 && meta.arg.viewId) {
        const oldPage = state.data.get(meta.arg.viewId);
        const newPage = buildViewMembersPage(payload);
        const updatedPage = mergeIncomingViewMembersPage({
          oldPage,
          newPage,
          totalCount: payload.totalCount
        });
        if (updatedPage) state.data = state.data.set(meta.arg.viewId, updatedPage);
      } else {
        if (type === fetchSpamViewMembers.fulfilled.type) {
          state.data = ImmutableMap({
            [SPAM_THREAD_LIST_ID]: buildViewMembersPage(payload)
          });
        } else if (meta.arg) {
          state.data = ImmutableMap({
            [meta.arg.viewId]: buildViewMembersPage(payload)
          });
        }
      }
      state.status = AsyncStatus.SUCCEEDED;
      state.loading = false;
    }).addMatcher(isAnyOf(fetchSpamViewMembers.rejected, fetchViewMembers.rejected, fetchSearchAndFilterViewMembers.rejected), state => {
      state.status = AsyncStatus.FAILED;
      state.loading = false;
      state.failed = true;
    });
  }
});