import { GLOBAL_NAME } from './constants/Globals';
import handleRemoveEventListener from './lib/handleRemoveCallingEventListener';
import handleTriggerCallingEvent from './lib/handleTriggerCallingEvent';
import handleAddCallingEventListener from './lib/handleAddCallingEventListener';
import handleOnCallSavedThirdParty from './lib/handleOnCallSavedThirdParty';
import { UPDATE_THREAD_ID, PAGE_NAVIGATION, GET_CAPABILITIES, HIDE_WIDGET, OPEN_WIDGET, PRELOAD, SET_CALLING_PROVIDER_SETTING, SET_CALL_METHOD, SET_CAPABILITIES, UPDATE_CALLEE_NUMBER, UPDATE_READY_STATE, UPDATE_IS_QUEUE_TASK, MINIMIZE_CALLING_WIDGET, ADD_EVENT_LISTENER, REMOVE_EVENT_LISTENER, TRIGGER_EVENT_LISTENER, ADD_CALLABLE_ASSOCIATION, REMOVE_CALLABLE_ASSOCIATION, SET_BROWSER_HISTORY, START_CALL, ADD_PHONE_NUMBER, REGISTER_FROM_NUMBER, LEARN_MORE, OPEN_THIRD_PARTY_WIDGET, HIDE_THIRD_PARTY_WIDGET, UPDATE_THIRD_PARTY_SUBJECT, ON_CALL_SAVED_THIRD_PARTY } from './constants/MethodNames';
import handleGetCapabilities from './lib/handleGetCapabilities';
import handlePageNavigation from './lib/handlePageNavigation';
import handleSetBrowserHistory from './lib/handleSetBrowserHistory';
import setCapabilitiesHandler from './lib/setCapabilitiesHandler';
import hideWidgetHandler from './lib/hideWidgetHandler';
import updateReadyState from './lib/updateReadyState';
import preloadWidgetHandler from './lib/preloadWidgetHandler';
import setCallProviderSetting from './lib/setCallingProvidersSetting';
import setCallMethodHandler from './lib/setCallMethodHandler';
import updateThreadIdHandler from './lib/updateThreadIdHandler';
import addCallableAssociationHandler from './lib/addCallableAssociationHandler';
import removeCallableAssociationHandler from './lib/removeCallableAssociationHandler';
import minimizeWidgetHandler from './lib/minimizeWidgetHandler';
import updateCalleeNumber from './lib/updateCalleeNumber';
import updateIsQueueTaskHandler from './lib/updateIsQueueTaskHandler';
import startCallHandler from './lib/startCallHandler';
import addPhoneNumberHandler from './lib/addPhoneNumberHandler';
import learnMoreHandler from './lib/learnMoreHandler';
import registerFromNumberHandler from './lib/registerFromNumberHandler';
import openWidget from './lib/handleOpenWidget';
import { logError } from './utils/logError';
import openThirdPartyWidget from './lib/openThirdPartyWidgetHandler';
import hideThirdPartyWidget from './lib/hideThirdPartyWidgetHandler';
import handleUpdateThirdPartySubject from './lib/handleUpdateThirdPartySubject';
export const initializeGlobalApi = () => {
  if (!window[GLOBAL_NAME]) {
    window[GLOBAL_NAME] = {};
  }
  if (!window[GLOBAL_NAME].initialized) {
    window[GLOBAL_NAME] = Object.assign(window[GLOBAL_NAME], {
      capabilities: {
        customAssociations: true,
        callOutcomes: true,
        callTypes: true,
        callingProviders: true,
        fontStyles: true,
        followupTasks: true
      },
      lastSentReadyState: {},
      lastSentThirdPartySubject: {},
      updateQueueByEventType: {},
      isReady: false,
      initialized: true,
      [ADD_EVENT_LISTENER]: handleAddCallingEventListener,
      [REMOVE_EVENT_LISTENER]: handleRemoveEventListener,
      [TRIGGER_EVENT_LISTENER]: handleTriggerCallingEvent,
      [GET_CAPABILITIES]: handleGetCapabilities,
      [PAGE_NAVIGATION]: handlePageNavigation,
      [SET_BROWSER_HISTORY]: handleSetBrowserHistory,
      [UPDATE_READY_STATE]: updateReadyState,
      [HIDE_WIDGET]: hideWidgetHandler,
      [PRELOAD]: preloadWidgetHandler,
      [SET_CAPABILITIES]: setCapabilitiesHandler,
      [SET_CALL_METHOD]: setCallMethodHandler,
      [ADD_CALLABLE_ASSOCIATION]: addCallableAssociationHandler,
      [REMOVE_CALLABLE_ASSOCIATION]: removeCallableAssociationHandler,
      [UPDATE_IS_QUEUE_TASK]: updateIsQueueTaskHandler,
      [MINIMIZE_CALLING_WIDGET]: minimizeWidgetHandler,
      [UPDATE_THREAD_ID]: updateThreadIdHandler,
      [UPDATE_CALLEE_NUMBER]: updateCalleeNumber,
      [SET_CALLING_PROVIDER_SETTING]: setCallProviderSetting,
      [START_CALL]: startCallHandler,
      [LEARN_MORE]: learnMoreHandler,
      [ADD_PHONE_NUMBER]: addPhoneNumberHandler,
      [REGISTER_FROM_NUMBER]: registerFromNumberHandler,
      [OPEN_WIDGET]: openWidget,
      [OPEN_THIRD_PARTY_WIDGET]: openThirdPartyWidget,
      [HIDE_THIRD_PARTY_WIDGET]: hideThirdPartyWidget,
      [UPDATE_THIRD_PARTY_SUBJECT]: handleUpdateThirdPartySubject,
      [ON_CALL_SAVED_THIRD_PARTY]: handleOnCallSavedThirdParty
    });
  }
};
initializeGlobalApi();
export function setGlobalPostMessagingMethod(method) {
  if (!window[GLOBAL_NAME].postMessageToHubSpotIframe) {
    window[GLOBAL_NAME].postMessageToHubSpotIframe = method;
  }
  flushPostInternalMessageQueue();
}
export let postInternalMessage = (message, callback = () => {}) => {
  if (!window[GLOBAL_NAME].postMessageToHubSpotIframe) {
    if (!window[GLOBAL_NAME].postMessageQueue) {
      window[GLOBAL_NAME].postMessageQueue = [];
    }
    window[GLOBAL_NAME].postMessageQueue.push(message);
    return;
  }
  if (typeof callback !== 'function') {
    logError(new Error('[Calling Global API Error] expected postInternalMessage callback to be a function'), {
      extra: {
        message,
        callback
      }
    });
    window[GLOBAL_NAME].postMessageToHubSpotIframe(message, null);
    return;
  }
  window[GLOBAL_NAME].postMessageToHubSpotIframe(message, callback);
};
export function flushPostInternalMessageQueue() {
  if (window[GLOBAL_NAME].postMessageQueue && window[GLOBAL_NAME].postMessageQueue.length > 0) {
    window[GLOBAL_NAME].postMessageQueue.forEach(message => {
      postInternalMessage(message);
    });
    window[GLOBAL_NAME].postMessageQueue = [];
  }
}
export function initializeCallingGlobal() {
  window[GLOBAL_NAME].isReady = true;
  if (window.hsCallingOnReady && window.hsCallingOnReady.length) {
    window.hsCallingOnReady.forEach(callback => {
      callback();
    });
    window.hsCallingOnReady = [];
  }
}
export const setPostInternalMessageForTesting = f => {
  postInternalMessage = f;
};