import { useEffect, useState } from 'react';
import { checkNetwork } from 'conversations-http/network/checkNetwork';
import enviro from 'enviro';
export const useCheckNetwork = () => {
  const [isOnline, setIsOnline] = useState(false);
  const isDeployed = enviro.deployed();
  useEffect(() => {
    if (isDeployed) {
      checkNetwork().then(({
        online,
        error
      }) => {
        if (error && error.status === 200 && /* eslint-disable-next-line hubspot-dev/no-hublet-comparison, hubspot-dev/no-hublet-references */
        enviro.getHublet() !== 'na1' && error.message.includes('CORS')) {
          setIsOnline(true);
        } else {
          setIsOnline(online);
        }
      }).catch(e => {
        void e;
      });
    }
  });
  return isOnline;
};