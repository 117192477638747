// adapted from: https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/24c061ac8fe4f3aee9b0a5f69a2d760a19563fd5/static/js/routing-rules/operators/routingRulesSetters.js

import curry from 'transmute/curry';
import pipe from 'transmute/pipe';
import setIn from 'transmute/setIn';
import { DISTRIBUTIONS } from '../constants/distributions';
import { DISTRIBUTION, FALLBACK, ROTATION_METHOD, TEAM_IDS, USER_IDS } from '../constants/keyPaths';
import { checkCanHaveFallback, checkHasUsersAndTeams, getFallback } from './routingRulesGetters';
const _setDistribution = curry((distribution, routingRules) => {
  let finalDistribution = DISTRIBUTIONS.BALANCED;
  let rotationMethod = DISTRIBUTIONS.LEAST_BUSY;
  if (distribution === DISTRIBUTIONS.RANDOM) {
    finalDistribution = DISTRIBUTIONS.RANDOM;
    rotationMethod = DISTRIBUTIONS.RANDOM;
  }
  return setIn(ROTATION_METHOD, rotationMethod, setIn(DISTRIBUTION, finalDistribution, routingRules));
});
export function setDistribution(distribution, routingRules) {
  return routingRules ? _setDistribution(distribution, routingRules) : _setDistribution(distribution);
}
export const setFallback = setIn(FALLBACK);
export const setTeamIds = setIn(TEAM_IDS);
export const setUserIds = setIn(USER_IDS);
const _setUsersAndTeams = (userIds, teamIds) => pipe(setUserIds(userIds), setTeamIds(teamIds));
export const setUsersAndTeams = (routingRules, userIds, teamIds) => {
  if (!checkHasUsersAndTeams(routingRules)) {
    return routingRules;
  }
  const setIds = _setUsersAndTeams(userIds, teamIds);
  if (checkCanHaveFallback(routingRules)) {
    const newFallback = setIds(getFallback(routingRules));
    return setFallback(newFallback, routingRules);
  }
  return setIds(routingRules);
};