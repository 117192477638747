import styled from 'styled-components';
export const FlexGrow = styled.div.withConfig({
  displayName: "StyledComponents__FlexGrow",
  componentId: "sc-179t8vu-0"
})(["flex-grow:1;"]);
export const FlexShrink = styled.div.withConfig({
  displayName: "StyledComponents__FlexShrink",
  componentId: "sc-179t8vu-1"
})(["flex:1;word-break:normal;"]);
export const Flex = styled.div.withConfig({
  displayName: "StyledComponents__Flex",
  componentId: "sc-179t8vu-2"
})(["display:flex;align-items:center;"]);
export const Small = styled.small.withConfig({
  displayName: "StyledComponents__Small",
  componentId: "sc-179t8vu-3"
})(["display:block;line-height:16px;"]);