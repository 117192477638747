// Adapted from:
//  - https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/24c061ac8fe4f3aee9b0a5f69a2d760a19563fd5/static/js/routing-rules/builders/buildRoutingRules.ts
//  - https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/24c061ac8fe4f3aee9b0a5f69a2d760a19563fd5/static/js/routing-rules/builders/buildRoutingRulesFromType.ts
import { fromJS } from 'immutable';
import get from 'transmute/get';
import set from 'transmute/set';
import { ROUTING_RULE_TYPES } from '../constants/RoutingTypes';
import { getType } from '../operators/routingRulesGetters';
import { buildAiChatbotRoutingRules } from '../records/AiChatbotRoutingRules';
import { buildBotRoutingRules } from '../records/BotRoutingRules';
import { buildInboxUsersRoutingRules } from '../records/InboxUsersRoutingRules';
import { buildOwnerRoutingRules } from '../records/OwnerRoutingRules';
import { buildUnassignedRoutingRules } from '../records/UnassignedRoutingRules';
import { buildUsersAndTeamsRoutingRules } from '../records/UsersAndTeamsRoutingRules';
import { getChannelRoutingDistribution } from './distribution';
import { setDistribution } from '../operators/routingRulesSetters';
export const setFallback = options => {
  const optionMap = fromJS(options || {});
  const fallback = get('fallback', optionMap);

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return set('fallback', buildRoutingRules(fallback), optionMap);
};
export function buildRoutingRulesFromType(routingType, options) {
  switch (routingType) {
    case ROUTING_RULE_TYPES.USERS_AND_TEAMS:
      return buildUsersAndTeamsRoutingRules(options);
    case ROUTING_RULE_TYPES.OWNER:
      return buildOwnerRoutingRules(options);
    case ROUTING_RULE_TYPES.INBOX_USERS:
      return buildInboxUsersRoutingRules(options);
    case ROUTING_RULE_TYPES.BOT:
      return buildBotRoutingRules(options);
    case ROUTING_RULE_TYPES.AI_CHATBOT:
      return buildAiChatbotRoutingRules(options);
    default:
      return buildUnassignedRoutingRules(options);
  }
}
export function buildChannelRoutingRules(channelType, routingType, maybeOptions) {
  const distribution = getChannelRoutingDistribution(channelType);
  const options = setDistribution(distribution, maybeOptions || {});
  return buildRoutingRulesFromType(routingType, options);
}
export function buildRoutingRules(options) {
  const routingType = getType(options);
  return buildRoutingRulesFromType(routingType, options || undefined);
}