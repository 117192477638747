import { getFullUrlWithCurrentSubDomain } from 'hubspot-url-utils';
import PortalIdParser from 'PortalIdParser';
let appUrls;
const getAppUrls = () => {
  if (appUrls) return appUrls;
  const baseUrl = getFullUrlWithCurrentSubDomain();
  const portalId = PortalIdParser.get();
  appUrls = {
    callingUserPreferencesUrl: `${baseUrl}/settings/${portalId}/user-preferences/calling`,
    callingConfigurationSettingsUrl: `${baseUrl}/settings/${portalId}/calling/callconfigurations`,
    supportUrl: `${baseUrl}/support/${portalId}/open`,
    callingToolSettingsUrl: `${baseUrl}/settings/${portalId}/calling`,
    callingMainSettingsUrl: `${baseUrl}/settings/${portalId}/calling/numbers`,
    paidUsersManagementUrl: `${baseUrl}/settings/${portalId}/users/paid-users`,
    integrationsUrl: `${baseUrl}/ecosystem/${portalId}/marketplace/apps/sales/calling`,
    accountAndBillingUrl: `${baseUrl}/account-and-billing/${portalId}/overview`,
    callIndexUrl: `${baseUrl}/contacts/${portalId}/objects/0-48/views/all/list`,
    integrationsSettingsUrl: `${baseUrl}/integrations-settings/${portalId}/installed`,
    inboxSettingsUrl: `${baseUrl}/live-messages-settings/${portalId}/inboxes`,
    helpDeskSettingsUrl: `${baseUrl}/helpdesk-settings/${portalId}/inboxes`,
    marketplace: `${baseUrl}/ecosystem/${portalId}/marketplace/apps/sales/calling`,
    callingIvrSettingsUrl: `${baseUrl}/settings/${portalId}/calling/ivr`,
    usersAndTeamsSettingsUrl: `${baseUrl}/settings/${portalId}/users`,
    individualUserSettingsUrl: `${baseUrl}/settings/${portalId}/users/user/` //append userId
  };
  return appUrls;
};
export default getAppUrls;