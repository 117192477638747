import { END_CALL_ON_DEVICE_MESSAGE, KEYPAD_NUMBER_PRESS_DEVICE_MESSAGE, MUTE_DEVICE_MESSAGE, REFRESH_TAB_MESSAGE, INITIATE_OUTBOUND_CALL_MESSAGE, SELECT_MICROPHONE_DEVICE_MESSAGE, SELECT_SPEAKER_DEVICE_MESSAGE, SET_THREAD_ID, TOGGLE_CALL_ON_HOLD_MESSAGE, ANSWER_TRANSFER_REQUEST_MESSAGE, REJECT_TRANSFER_REQUEST_MESSAGE, CONFIRM_TRANSFER_HANDOFF_MESSAGE, CANCEL_TRANSFER_REQUEST_MESSAGE, INITIATE_TRANSFER_REQUEST_MESSAGE, TOGGLE_RECORDING_MESSAGE, TOGGLE_RECORDING_MODAL_MESSAGE, CLOSE_ONE_PARTY_CONSENT_RECORDING_ALERT_MESSAGE } from './deviceEventConstants';
import { MessageRelayTypes } from 'calling-cross-tab-library/types/MessageRelayTypes';
import { UUID } from 'shared-worker-versioning/lib/utils/UUID';
export const createEndCallMessage = ({
  destinationConnectionId
}) => {
  return {
    id: UUID(),
    type: END_CALL_ON_DEVICE_MESSAGE,
    destinationConnectionId,
    payload: {},
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createKeypadPressMessage = ({
  destinationConnectionId,
  keyPressed
}) => {
  return {
    id: UUID(),
    type: KEYPAD_NUMBER_PRESS_DEVICE_MESSAGE,
    destinationConnectionId,
    payload: {
      keyPressed
    },
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createMuteMessage = ({
  destinationConnectionId,
  muteStatus
}) => {
  return {
    id: UUID(),
    type: MUTE_DEVICE_MESSAGE,
    destinationConnectionId,
    payload: {
      muteStatus
    },
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createSelectMicrophoneDeviceMessage = ({
  destinationConnectionId,
  audioDeviceId
}) => {
  return {
    id: UUID(),
    type: SELECT_MICROPHONE_DEVICE_MESSAGE,
    destinationConnectionId,
    payload: {
      audioDeviceId
    },
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createSelectSpeakerDeviceMessage = ({
  destinationConnectionId,
  audioDeviceId
}) => {
  return {
    id: UUID(),
    type: SELECT_SPEAKER_DEVICE_MESSAGE,
    destinationConnectionId,
    payload: {
      audioDeviceId
    },
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createRefreshTabMessage = ({
  destinationConnectionId
}) => {
  return {
    id: UUID(),
    type: REFRESH_TAB_MESSAGE,
    destinationConnectionId,
    payload: {},
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createInitiateOutboundCallMessage = ({
  destinationConnectionId,
  callableObject,
  toNumber,
  fromNumber,
  source,
  accountType,
  requireContactOrCompany,
  threadId
}) => {
  return {
    id: UUID(),
    type: INITIATE_OUTBOUND_CALL_MESSAGE,
    destinationConnectionId,
    payload: {
      callableObject,
      toNumber,
      fromNumber,
      source,
      accountType,
      requireContactOrCompany,
      threadId
    },
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createSetThreadIdMessage = ({
  destinationConnectionId,
  threadId
}) => {
  return {
    id: UUID(),
    type: SET_THREAD_ID,
    destinationConnectionId,
    payload: {
      threadId
    },
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createToggleOnHoldMessage = ({
  onHold,
  destinationConnectionId
}) => {
  return {
    id: UUID(),
    type: TOGGLE_CALL_ON_HOLD_MESSAGE,
    destinationConnectionId,
    payload: {
      onHold
    },
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createAnswerTransferRequestMessage = ({
  destinationConnectionId
}) => {
  return {
    id: UUID(),
    type: ANSWER_TRANSFER_REQUEST_MESSAGE,
    destinationConnectionId,
    payload: {},
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createRejectTransferRequestMessage = ({
  destinationConnectionId
}) => {
  return {
    id: UUID(),
    type: REJECT_TRANSFER_REQUEST_MESSAGE,
    destinationConnectionId,
    payload: {},
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createConfirmTransferHandoffMessage = ({
  destinationConnectionId
}) => {
  return {
    id: UUID(),
    type: CONFIRM_TRANSFER_HANDOFF_MESSAGE,
    destinationConnectionId,
    payload: {},
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createCancelTransferRequestMessage = ({
  destinationConnectionId
}) => {
  return {
    id: UUID(),
    type: CANCEL_TRANSFER_REQUEST_MESSAGE,
    destinationConnectionId,
    payload: {},
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createInitiateTransferRequest = ({
  destinationConnectionId,
  transfereeName,
  transfererName,
  transfererEmail,
  transfererUserId
}) => {
  return {
    id: UUID(),
    type: INITIATE_TRANSFER_REQUEST_MESSAGE,
    destinationConnectionId,
    payload: {
      transfereeName,
      transfererName,
      transfererEmail,
      transfererUserId
    },
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createToggleRecordingMessage = ({
  destinationConnectionId,
  recordingStatus
}) => {
  return {
    id: UUID(),
    type: TOGGLE_RECORDING_MESSAGE,
    destinationConnectionId,
    payload: {
      recordingStatus
    },
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createToggleRecordingModalMessage = ({
  destinationConnectionId,
  recordingModalStatus
}) => {
  return {
    id: UUID(),
    type: TOGGLE_RECORDING_MODAL_MESSAGE,
    destinationConnectionId,
    payload: {
      recordingModalStatus
    },
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};
export const createCloseOnePartyConsentRecordingAlertMessage = ({
  destinationConnectionId
}) => {
  return {
    id: UUID(),
    type: CLOSE_ONE_PARTY_CONSENT_RECORDING_ALERT_MESSAGE,
    destinationConnectionId,
    payload: {},
    metadata: {
      messageRelayType: MessageRelayTypes.BROADCAST
    }
  };
};